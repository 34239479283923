import React, {
    useMemo
} from "react";
import {
    Portal,
} from "@fcpro/components";
import GlobalSearchApp from "./GlobalSearchApp";

export interface GlobalSearchAppInitProps {

}
const GlobalSearchAppInit: React.FunctionComponent<GlobalSearchAppInitProps> = ({

}) => {
    const els = useMemo<Array<HTMLElement>>(() => {
        return Array.from(document.querySelectorAll('.js-global-search-app'));
    }, []);

    if (els.length === 0) {
        return null;
    }

    return (<>
        {els.map((el, idx) => {
            const input = el.querySelector('.search-box__input') as HTMLInputElement;
            return (
                <Portal
                    key={idx}
                    ComponentNode={
                        <GlobalSearchApp
                            initialText={input.value}
                            isInitiallyFocused={document.activeElement === input}
                            placeholder={el.dataset?.placeholder}
                            moreButtonBaseUrl={el.dataset?.moreButtonBaseUrl}
                            enableCloseButton={(el.dataset?.enableCloseButton ?? null) === 'true'}
                            enableBodyOverflow={(el.dataset?.enableBodyOverflow ?? null) === 'true'}
                            theme={el.dataset?.theme as any}
                        />
                    }
                    container={el}
                />
            );
        })}
    </>);
}

export default GlobalSearchAppInit;