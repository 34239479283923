import React, {
    useCallback,
    useState
} from "react";
import {
    SearchAutocomplete,
    SearchAutocompleteProps,
} from "@fcpro/components";


interface GlobalSearchAppProps {
    initialText?: string,
    isInitiallyFocused?: boolean,
    placeholder?: string,
    moreButtonBaseUrl?: string,
    enableCloseButton?: boolean,
    enableBodyOverflow?: boolean,
    theme?: SearchAutocompleteProps['theme'],
}
const GlobalSearchApp: React.FunctionComponent<GlobalSearchAppProps> = ({
    initialText,
    isInitiallyFocused,
    placeholder,
    moreButtonBaseUrl,
    enableCloseButton,
    enableBodyOverflow,
    theme,
}) => {
    const [isActive, setIsActive] = useState<boolean>(false);

    const handleInputFocus = useCallback(() => {
        if (!isActive) {
            setIsActive(true);
        }
    }, [isActive]);
    const handleCloseRequest = useCallback(() => {
        setIsActive(false);
    }, []);

    return (
        <SearchAutocomplete
            initialText={initialText}
            isInitiallyFocused={isInitiallyFocused}
            placeholder={placeholder}
            moreButtonBaseUrl={moreButtonBaseUrl}
            enableCloseButton={enableCloseButton}
            enableBodyOverflow={enableBodyOverflow}
            theme={theme}
            isActive={isActive}
            onCloseRequest={handleCloseRequest}
            onInputFocus={handleInputFocus}
        />
    )
}

export default GlobalSearchApp;