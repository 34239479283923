import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import {
    SearchAutocomplete,
    SearchAutocompleteProps,
    SearchSolidSvg,
    TimesSolidSvg,
} from "@fcpro/components";
import clsx from "clsx";


interface DesktopGlobalSearchAppProps {
    placeholder?: string,
    moreButtonBaseUrl?: string,
    enableCloseButton?: boolean,
    enableBodyOverflow?: boolean,
    theme?: SearchAutocompleteProps['theme'],
}
const DesktopGlobalSearchApp: React.FunctionComponent<DesktopGlobalSearchAppProps> = ({
    placeholder,
    moreButtonBaseUrl,
    enableCloseButton,
    enableBodyOverflow,
    theme,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleToggleOpen = useCallback(() => {
        setIsOpen((prevState) => !prevState);
    }, []);

    const handleCloseRequest = useCallback(() => {
        setIsOpen(false);
    }, []);

    useEffect(() => {
        const searchButton = document.querySelector('.desktop-global-search-app-key-listener');

        const handleKeyDownEvents = (e: KeyboardEvent) => {
            const allInputEls: Array<HTMLInputElement> = Array.from(document.querySelectorAll('input:focus, [contenteditable="true"]:focus')) as HTMLInputElement[];

            if (e.key === 'Escape') {
                if (isOpen) {
                    e.preventDefault();
                    setIsOpen(false)
                }
            }

            if (allInputEls.length === 0) {
                if (e.key === '/') {
                    e.preventDefault();
                    handleToggleOpen();
                }
            }
        }

        if (searchButton) {
            window.addEventListener('keydown', handleKeyDownEvents);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDownEvents);
        }
    }, [handleToggleOpen]);

    return (
        <div className="desktop-global-search-app">
            <div className={clsx({
                "desktop-global-search-app__search": true,
                "desktop-global-search-app__search--is-open": isOpen,
            })}>
                <div className={clsx({
                    "desktop-global-search-app__search-inner": true,
                    "desktop-global-search-app__search-inner--is-open": isOpen,
                })}>
                    {isOpen &&
                        <SearchAutocomplete
                            isInitiallyFocused={true}
                            placeholder={placeholder}
                            moreButtonBaseUrl={moreButtonBaseUrl}
                            enableCloseButton={enableCloseButton}
                            enableBodyOverflow={enableBodyOverflow}
                            theme={theme}
                            onCloseRequest={handleCloseRequest}
                            isActive={isOpen}
                        />
                    }
                </div>
            </div>
            {!isOpen && <p className="desktop-global-search-app__instruction">Hit "/" to open search</p>}
            <div
                className={clsx({
                    "desktop-global-search-app__control": true,
                    "desktop-global-search-app__control--is-open": isOpen,
                    "desktop-global-search-app-key-listener": true,
                })}
                onClick={handleToggleOpen}
            >
                {isOpen ?
                    <TimesSolidSvg />
                    :
                    <SearchSolidSvg />
                }
            </div>
        </div>
    )
}

export default DesktopGlobalSearchApp;