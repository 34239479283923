import React, {
    Suspense,
    useMemo
} from "react";
import {
    Portal,
} from "@fcpro/components";
import { SquadBuilderConstantsEnum } from "@futgg/core";

const SubRenderAppInit: React.FunctionComponent = () => {
    const el = useMemo<HTMLElement | null>(() => {
        return document.getElementById('js-subs-render-app');
    }, []);

    if (el === null) {
        return null;
    }

    const SubsRenderAppRoot = React.lazy(() => import("./containers/SubsRenderAppRoot"));

    const eventGuid = el.dataset?.eventUuid ?? null;
    const squadUuid = el.dataset?.squadUuid ?? null;

    if (eventGuid === null || squadUuid === null) {
        return (
            <div className="error" style={{color: '#000'}}>ERROR: Missing Event UUID or Squad UUID</div>
        );
    }

    return (<>
        <Portal
            ComponentNode={
                <Suspense fallback={<div dangerouslySetInnerHTML={{__html: el.innerHTML}} />}>
                    <SubsRenderAppRoot
                        gameSlug={el.dataset?.gameSlug ?? SquadBuilderConstantsEnum.GAME_SLUG}
                        squadUuid={squadUuid}
                        eventGuid={eventGuid}
                    />
                </Suspense>
            }
            container={el}
        />
    </>);
}

export default SubRenderAppInit;