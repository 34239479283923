import React, {
    Suspense,
    useMemo
} from "react";
import {
    LoadingPlaceholder,
    Portal,
} from "@fcpro/components";
import {AppConstantsEnum} from "./constants/AppConstantsEnum";
import { SquadBuilderConstantsEnum } from "@futgg/core";

export interface SquadBuilderAppInitInitProps {

}
const SquadBuilderAppInit: React.FunctionComponent<SquadBuilderAppInitInitProps> = ({

}) => {
    const el = useMemo<HTMLElement | null>(() => {
        return document.getElementById(AppConstantsEnum.APP_TARGET_ID);
    }, []);

    if (el === null) {
        return null;
    }

    const SquadBuilderAppRoot = React.lazy(() => import("./containers/SquadBuilderAppRoot"));

    const eventGuid = el.dataset?.eventGuid ?? null;

    if (eventGuid === null) {
        console.error('SquadBuilderAppInit: eventGuid is null');
        return (
            <div>ERROR: Missing Event GUID</div>
        );
    }

    return (<>
        <Portal
            ComponentNode={
                <Suspense fallback={<div dangerouslySetInnerHTML={{__html: el.innerHTML}} />}>
                    <SquadBuilderAppRoot
                        gameSlug={el.dataset?.gameSlug ?? SquadBuilderConstantsEnum.GAME_SLUG}
                        isReadOnly={el.dataset?.isReadOnly === 'True'}
                        isCurrent={el.dataset?.isCurrent === 'True'}
                        useMobileCompactDefault={el.dataset?.useMobileCompactDefault === 'False' ? false : undefined}
                        squadUuid={el.dataset?.squadId ?? null}
                        eventGuid={eventGuid}
                        initialHtml={el.innerHTML}
                    />
                </Suspense>
            }
            container={el}
        />
    </>);
}

export default SquadBuilderAppInit;