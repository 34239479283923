import React, {
    Suspense,
    useMemo
} from "react";
import {
    Portal,
    Skeleton
} from "@fcpro/components";
import {FormUtils} from "@fcpro/core";

interface IEntityViewGroup {
    els: Array<HTMLElement>,
    contentTypeId: number,
    allEntityIds: Array<number>,
}

export interface EntityViewCountAppInitProps {

}
const EntityViewCountAppInit: React.FunctionComponent<EntityViewCountAppInitProps> = ({

}) => {
    const elGroups = useMemo<Array<IEntityViewGroup>>(() => {
        const els = Array.from(document.querySelectorAll('.js-entity-view-count-app')) as Array<HTMLElement>;

        const entityElLookup: Record<number, Array<HTMLElement>> = {};
        els.forEach((el) => {
            const contentTypeId: number | null = FormUtils.parseInputInt(el.dataset?.contentTypeId ?? "");
            if (contentTypeId === null) {
                return;
            }
            if (!entityElLookup.hasOwnProperty(contentTypeId)) {
                entityElLookup[contentTypeId] = [];
            }
            entityElLookup[contentTypeId].push(el);
        });

        const entityViewGroups: Array<IEntityViewGroup> = [];
        Object.keys(entityElLookup).forEach((rawContentTypeId) => {
            const contentTypeId = FormUtils.parseInputInt(rawContentTypeId);
            if (contentTypeId === null) {
                return;
            }
            const els = entityElLookup[contentTypeId] ?? [];
            entityViewGroups.push({
                els,
                contentTypeId,
                allEntityIds: els.map((el) => FormUtils.parseInputInt(el.dataset?.entityId ?? "") ?? 0),
            });
        });

        return entityViewGroups;
    }, []);

    if (elGroups.length === 0) {
        return null;
    }

    const EntityViewCountApp = React.lazy(() => import("./EntityViewCountApp"));

    return (<>
        {elGroups.map((group, groupIdx) =>
            group.els.map((el, elIdx) => {
                const entityId: number | null = FormUtils.parseInputInt(el.dataset?.entityId ?? "");
                if (entityId === null) {
                    return;
                }

                const showIconRaw: string | undefined = el.dataset?.showIcon;
                const showIcon: boolean | undefined = showIconRaw === undefined ? undefined : showIconRaw === 'true';
                const showLabelRaw: string | undefined = el.dataset?.showLabel;
                const showLabel: boolean | undefined = showLabelRaw === undefined ? undefined : showLabelRaw === 'true';
                const skeletonSizeRaw: string | undefined = el.dataset?.skeletonSize;
                const skeletonSize: number | null = skeletonSizeRaw === undefined ? null : FormUtils.parseInputInt(skeletonSizeRaw);
                return (
                    <Portal
                        key={`${group.contentTypeId}-${entityId}-${elIdx}`}
                        ComponentNode={
                            <Suspense fallback={<div className="d-inline-flex align-top"><Skeleton width={40} /></div>}>
                                <EntityViewCountApp
                                    allEntityIds={group.allEntityIds}
                                    entityId={entityId}
                                    contentTypeId={group.contentTypeId}
                                    showIcon={showIcon}
                                    showLabel={showLabel}
                                    skeletonSize={skeletonSize ?? undefined}
                                />
                            </Suspense>
                        }
                        container={el}
                    />
                );
            })
        )}
    </>);
}

export default EntityViewCountAppInit;