import React from "react";
import {
    AnalyticTypeEnum,
    fcproApi
} from "@fcpro/core";


interface AnalyticsEntityViewProps {
    contentTypeId: number,
    entityId: number,
}
const AnalyticsEntityView: React.FunctionComponent<AnalyticsEntityViewProps> = ({
    contentTypeId,
    entityId,
}) => {
    fcproApi.useTrackEntityViewQuery({
        type: AnalyticTypeEnum.ENTITY_VIEW,
        contentTypeId,
        entityId,
    });    
    return null;
};

export default AnalyticsEntityView;