import React, {
    Suspense,
    useMemo
} from "react";
import {
    Portal,
    Skeleton
} from "@fcpro/components";
import {FormUtils} from "@fcpro/core";

export interface CommentCountAppInitProps {

}
const CommentCountAppInit: React.FunctionComponent<CommentCountAppInitProps> = ({

}) => {
    const els = useMemo<Array<HTMLElement>>(() => {
        return Array.from(document.querySelectorAll('.js-comment-count-app'));
    }, []);
    const allCommentEntityIds = useMemo<Array<number>>(() => {
        const ids = new Set<number>();
        els.map((el) => {
            const entityId: number | null = FormUtils.parseInputInt(el.dataset?.entityId ?? "");
            if (entityId) {
                ids.add(entityId);
            }
        });
        return Array.from(ids);
    }, [els]);

    if (els.length === 0) {
        return null;
    }

    const CommentCountApp = React.lazy(() => import("./CommentCountApp"));

    return (<>
        {els.map((el, idx) => {
            const entityId: number | null = FormUtils.parseInputInt(el.dataset?.entityId ?? "");
            const contentTypeId: number | null = FormUtils.parseInputInt(el.dataset?.contentTypeId ?? "");
            if (entityId !== null && contentTypeId !== null) {
                const showIconRaw: string | undefined = el.dataset?.showIcon;
                const showIcon: boolean | undefined = showIconRaw === undefined ? undefined : showIconRaw === 'true';
                const showLabelRaw: string | undefined = el.dataset?.showLabel;
                const showLabel: boolean | undefined = showLabelRaw === undefined ? undefined : showLabelRaw === 'true';
                const skeletonSizeRaw: string | undefined = el.dataset?.skeletonSize;
                const skeletonSize: number | null = skeletonSizeRaw === undefined ? null : FormUtils.parseInputInt(skeletonSizeRaw);
                return (
                    <Portal
                        key={idx}
                        ComponentNode={
                            <Suspense fallback={<div className="d-inline-flex align-top"><Skeleton width={40} /></div>}>
                                <CommentCountApp
                                    allEntityIds={allCommentEntityIds}
                                    entityId={entityId}
                                    contentTypeId={contentTypeId}
                                    showIcon={showIcon}
                                    showLabel={showLabel}
                                    skeletonSize={skeletonSize ?? undefined}
                                />
                            </Suspense>
                        }
                        container={el}
                    />
                );
            }
        })}
    </>);
}

export default CommentCountAppInit;