import React, {
    Suspense,
    useMemo
} from "react";
import {
    LoadingPlaceholder,
    Portal,
} from "@fcpro/components";
import {AppConstantsEnum} from "./constants/AppConstantsEnum";
import {FormUtils} from "@fcpro/core";

export interface CommentsAppInitProps {

}
const CommentsAppInit: React.FunctionComponent<CommentsAppInitProps> = ({

}) => {
    const els = useMemo<Array<HTMLElement>>(() => {
        return Array.from(document.querySelectorAll(AppConstantsEnum.APP_TARGET_CLASS));
    }, []);

    if (els.length === 0) {
        return null;
    }

    const CommentsAppRoot = React.lazy(() => import("./containers/CommentsAppRoot"));

    return (<>
        {els.map((el, idx) => {
            const entityId: number | null = FormUtils.parseInputInt(el.dataset?.entityId ?? "");
            const contentTypeId: number | null = FormUtils.parseInputInt(el.dataset?.contentTypeId ?? "");

            if (entityId === null || contentTypeId === null) {
                console.error('Missing entity or content type');
                return null;
            }

            return (
                 <Portal
                    key={idx}
                    ComponentNode={
                        <Suspense fallback={<>
                            <h2>Comments</h2>
                            <LoadingPlaceholder height="15rem" />
                        </>}>
                            <CommentsAppRoot entityId={entityId} contentTypeId={contentTypeId} />
                        </Suspense>
                    }
                    container={el}
                />
            )
        })}
    </>);
}

export default CommentsAppInit;