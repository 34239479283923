import React, {
    useMemo
} from "react";
import AnalyticsEntityView from "./AnalyticsEntityView";
import {FormUtils} from "@fcpro/core";

export interface AnalyticsAppInitProps {

}
const AnalyticsAppInit: React.FunctionComponent<AnalyticsAppInitProps> = ({

}) => {
    const els = useMemo<Array<HTMLDivElement>>(() => {
        return Array.from(document.querySelectorAll('[data-analytics-entity-view]'));
    }, []);

    if (els.length === 0) {
        return null;
    }

    return (<>
        {els.map((el, idx) => {
            const contentTypeId = FormUtils.parseInputInt(el.dataset?.analyticsEntityView ?? '');
            const entityId = FormUtils.parseInputInt(el.dataset?.entityId ?? '');

            if (contentTypeId === null) {
                console.log('Missing contentTypeId');
                return null;
            }

            if (entityId === null) {
                console.log('Missing entityId');
                return null;
            }

            return (
                <AnalyticsEntityView
                    key={idx}
                    contentTypeId={contentTypeId}
                    entityId={entityId}
                />
            )
        })}
    </>);
}

export default AnalyticsAppInit;