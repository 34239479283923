import React, {
    Suspense,
    useMemo
} from "react";
import {
    Portal,
    Skeleton
} from "@fcpro/components";

export interface UserMenuAppInitProps {

}
const UserMenuAppInit: React.FunctionComponent<UserMenuAppInitProps> = ({

}) => {
    const els = useMemo<Array<HTMLElement>>(() => {
        return Array.from(document.querySelectorAll('.js-user-menu-app'));
    }, []);

    if (els.length === 0) {
        return null;
    }

    const UserMenu = React.lazy(() => import("./UserMenu"));

    return (<>
        {els.map((el, idx) =>
            <Portal
                key={idx}
                ComponentNode={
                    <Suspense fallback={<Skeleton width={32} />}>
                        <UserMenu />
                    </Suspense>
                }
                container={el}
            />
        )}
    </>);
}

export default UserMenuAppInit;