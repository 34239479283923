import React, {
    Suspense,
    useMemo
} from "react";
import {
    Portal,
    Skeleton
} from "@fcpro/components";

export interface TimeDiffAppInitProps {

}
const TimeDiffAppInit: React.FunctionComponent<TimeDiffAppInitProps> = ({

}) => {
    const els = useMemo<Array<HTMLTimeElement>>(() => {
        return Array.from(document.querySelectorAll('.js-time-diff-app'));
    }, []);

    if (els.length === 0) {
        return null;
    }

    const TimeDiffApp = React.lazy(() => import("./TimeDiffApp"));

    return (<>
        {els.map((el, idx) =>
            <Portal
                key={idx}
                ComponentNode={
                    <Suspense fallback={<Skeleton width={50} className="d-inline-block align-top" />}>
                        <TimeDiffApp dateTime={el.dateTime ?? null} />
                    </Suspense>
                }
                container={el}
            />
        )}
    </>);
}

export default TimeDiffAppInit;