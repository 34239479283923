import React from "react";
import "regenerator-runtime/runtime";
import { Tooltip } from 'react-tooltip';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import {store} from "../../store";
import UserMenuAppInit from "../UserMenuApp/UserMenuAppInit";
import MobileUserMenuAppInit from "../MobileUserMenuApp/MobileUserMenuAppInit";
import CommentCountAppInit from "../CommentCountApp/CommentCountAppInit";
import TimeDiffAppInit from "../TimeDiffApp/TimeDiffAppInit";
import CommentsAppInit from "../CommentsApp/CommentsAppInit";
import AnalyticsAppInit from "../AnalyticsApp/AnalyticsAppInit";
import EntityViewCountAppInit from "../EntityViewCountApp/EntityViewCountAppInit";
import GlobalSearchAppInit from "../GlobalSearchApp/GlobalSearchAppInit";
import DesktopGlobalSearchAppInit from "../DesktopGlobalSearchApp/DesktopGlobalSearchAppInit";
import SquadBuilderAppInit from "../SquadBuilderApp/SquadBuilderAppInit";
import EaSocial1AppInit from "../EaSocial1App/EaSocial1AppInit";
import SubRenderAppInit from "../SubsRenderApp/SubRenderAppInit";
import Broadcast1RenderAppInit from "../Broadcast1RenderApp/Broadcast1RenderAppInit";
import Broadcast2RenderAppInit from "../Broadcast2RenderApp/Broadcast2RenderAppInit";
import EaSocial2AppInit from "../EaSocial2App/EaSocial2AppInit";
import EaSocial3AppInit from "../EaSocial3App/EaSocial3AppInit";


interface RootProps {

}
const Root: React.FunctionComponent<RootProps> = ({

}) => {
    return (
        <ReduxProvider store={store}>
            <BrowserRouter>
                {/* Baked in to package and loaded */}
                <GlobalSearchAppInit />
                <DesktopGlobalSearchAppInit />

                {/* Lazy Loaded */}
                <UserMenuAppInit />
                <MobileUserMenuAppInit />
                <CommentCountAppInit />
                <TimeDiffAppInit />
                <CommentsAppInit />
                <AnalyticsAppInit />
                <EntityViewCountAppInit />
                <SquadBuilderAppInit />

                {/* Lazy Loaded Renders */}
                <EaSocial1AppInit />
                <EaSocial2AppInit />
                <EaSocial3AppInit />
                <SubRenderAppInit />
                <Broadcast1RenderAppInit />
                <Broadcast2RenderAppInit />

                {/* Global Libs */}
                <Tooltip className="tooltip--standard fw-700" />
                <ToastContainer theme="dark" style={{zIndex: 2000000001}} />
            </BrowserRouter>
        </ReduxProvider>
    );
}

export default Root;