import React, {
    useMemo
} from "react";
import {
    Portal,
} from "@fcpro/components";
import DesktopGlobalSearchApp from "./DesktopGlobalSearchApp";

export interface DesktopGlobalSearchAppInitProps {

}
const DesktopGlobalSearchAppInit: React.FunctionComponent<DesktopGlobalSearchAppInitProps> = ({

}) => {
    const els = useMemo<Array<HTMLElement>>(() => {
        return Array.from(document.querySelectorAll('.js-desktop-global-search-app'));
    }, []);

    if (els.length === 0) {
        return null;
    }

    return (<>
        {els.map((el, idx) =>
            <Portal
                key={idx}
                ComponentNode={
                    <DesktopGlobalSearchApp
                        placeholder={el.dataset?.placeholder}
                        moreButtonBaseUrl={el.dataset?.moreButtonBaseUrl}
                        enableCloseButton={(el.dataset?.enableCloseButton ?? null) === 'true'}
                        enableBodyOverflow={(el.dataset?.enableBodyOverflow ?? null) === 'true'}
                        theme={el.dataset?.theme as any}
                    />
                }
                container={el}
            />
        )}
    </>);
}

export default DesktopGlobalSearchAppInit;