import {
    configureStore,
} from '@reduxjs/toolkit';
import {setupListeners} from "@reduxjs/toolkit/query";
import {
    fcproApi,
    modalSlice,
} from "@fcpro/core";
import {
    coreDataSlice,
    futggApi,
    playerAttributesSlice,
    squadBuilderSelectors,
    squadBuilderSlice,
    SquadBuilderPersistenceUtils,
} from "@futgg/core";

export const store = configureStore({
    reducer: {
        [futggApi.reducerPath]: futggApi.reducer,
        [fcproApi.reducerPath]: fcproApi.reducer,
        coreData: coreDataSlice.reducer,
        modal: modalSlice.reducer,
        playerAttributes: playerAttributesSlice.reducer,
        squadBuilder: squadBuilderSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(fcproApi.middleware)
            .concat(futggApi.middleware)
            .concat(({ getState }) => {
                return (next) => (action) => {
                    next(action);

                    const state = getState();
                    const squadUuid = squadBuilderSelectors.selectUuid(state);
                    const localStorageKeyPrefix = squadBuilderSelectors.selectLocalStorageKeyPrefix(state);

                    if (squadUuid !== null) {
                        return;
                    }

                    switch (action.type) {
                        case squadBuilderSlice.actions.setTitle.type:
                        case squadBuilderSlice.actions.addPlayerPosition.type:
                        case squadBuilderSlice.actions.removePlayerPosition.type:
                        case squadBuilderSlice.actions.updatePlayerPosition.type:
                        case squadBuilderSlice.actions.setActiveFormationId.type:
                        case squadBuilderSlice.actions.setPlayerPositionOverride.type:
                        case squadBuilderSlice.actions.setPlayerEvolutionOverride.type:
                        case squadBuilderSlice.actions.setPlayerIsUntradeableOverride.type:
                        case squadBuilderSlice.actions.setManagerLeagueId.type:
                        case squadBuilderSlice.actions.setManagerNationId.type:
                            SquadBuilderPersistenceUtils.setPersistedSquadByState(state, localStorageKeyPrefix);
                            break;
                    }

                    return;
                };
            })
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;